<template>
  <div>
    <b-row>
      <b-col md="6">
        <div
          v-if="isSearchable"
          class="d-flex align-items-center justify-content-start"
        >
          <autosuggest
            ref="autocomplete"
            v-model="searchQuery"
            class="l-table-suggestion"
            :suggestions="suggestions"
            :input-props="getSearchInputProps()"
            :table-suggestion="isSuggestionTable"
            :style="autoSuggestStyle"
            :table-suggestion-configs="tableSuggestionConfigs"
            v-bind="getSuggestProps()"
            @input="fetchResults"
            @keyup.enter="reloadData"
            @selected="selectHandler"
          />
          <b-button
            class="searchButton"
            variant="success"
            @click="reloadData"
          >
            <feather-icon
              icon="LSearchIcon"
              size="16"
            />
          </b-button>
        </div>
      </b-col>
      <b-col md="6">
        <div class="d-flex align-items-center justify-content-end">
          <button-dashed
            v-if="isDelete"
            ref="deleteBtn"
            :disabled="isBatchDeleteArrNotEmpty"
            :class="isBatchDeleteArrNotEmpty ? 'opacity-50': null"
            @click="deleteButtonHandler"
          >
            <feather-icon
              icon="LTrashIcon"
              size="22"
              style="padding: 3px"
              class="lightIcon m-0"
            />
          </button-dashed>
          <button-dashed
            v-if="isImport"
            @click="importButtonHandler"
          >
            <feather-icon
              icon="LImportIcon"
              size="16"
              class="lightIcon"
            />
            {{ $t('Import') }}
          </button-dashed>
          <button-dashed v-if="isPrint">
            <feather-icon
              icon="LPrintIcon"
              size="16"
              class="lightIcon"
            />
            {{ $t('Print') }}
          </button-dashed>
          <button-dashed
            v-if="trashListPath"
            class="dashed-btn-custom-style"
            @click="gotoNewPage({ name: trashListPath }, $event)"
          >
            <feather-icon
              icon="LTrashOpenIcon"
              size="16"
              class="lightIcon"
            />
          </button-dashed>
          <button-dashed
            v-if="backToListPath"
            class="font-weight-bolder mr-0"
            @click="$router.push({ name: backToListPath })"
          >
            {{ $t('Back to List') }}
          </button-dashed>
          <slot name="filter">
            <feather-icon
              v-if="createPageUrl"
              v-b-tooltip.noninteractive.hover
              icon="LAddButtonIcon"
              :title="$t('Create')"
              class="cursor-pointer"
              size="34"
              @click="gotoNewPage(createPageUrl, $event)"
            />
          </slot>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="6"
        class="d-flex justify-content-start my-1"
      >
        <button-dashed
          v-if="filterClass"
          @click="filterToggle = !filterToggle"
        >
          <feather-icon
            icon="LFilterIcon"
            size="16"
            class="lightIcon"
          />
          <span class="filterTitle"> {{ $t('Filter By') }}</span>
          <feather-icon
            icon="LArrowDownIcon"
            size="16"
            class="defaultIconColor"
          />
        </button-dashed>
      </b-col>
    </b-row>
    <b-row v-if="filterClass">
      <b-col md="12">
        <component
          :is="`${moduleName}FilterComponent`"
          :filter-toggle="filterToggle"
          :filters="filters"
          :filter-class="filterClass"
          :selected-filters="selectedFilters"
          @searchQueryClear="searchQueryClear"
          @onFilter="onFilter"
          @onRemoveSelectedFilter="reloadData"
        />
      </b-col>
    </b-row>
    <!--Table == == == == == == == == == == == == -->
    <b-table-simple
      responsive
      striped
      class="dragg-table"
    >
      <b-thead>
        <b-tr>
          <b-th style="width: 10px; padding: 0 !important;" />
          <b-th
            v-for="col in tableColumns"
            :key="col.key"
            scope="col"
            :style="col.thStyle"
          >
            <slot
              :name="headSlotName(col.key)"
              :data="col"
            >
              {{ getHeader(col) }}
            </slot>
          </b-th>
        </b-tr>
      </b-thead>
      <draggable
        v-if="!isBusy"
        v-model="items"
        tag="tbody"
        table-class="table-sm"
        v-bind="defaultDragOptions()"
        @start="onDragStart"
        @end="onDragEnd"
        @change="onChoose"
      >
        <b-tr
          v-for="item in items"
          :key="item.id"
          @mouseenter="onMouseEnterToTableRowAppearDragIcon(item.id)"
          @mouseleave="onMouseLeaveToTableRowAppearDragIcon(item.id)"
        >
          <b-td style="padding: 1px 3px 1px 10px !important;">
            <div
              :class="item.id === dragIconVisibleId ? 'line-btn--visible': 'line-btn--unVisible'"
              class="line-btn"
            >
              <div class="line-btn--line" />
              <div class="line-btn--line" />
            </div>
          </b-td>
          <b-td
            v-for="col in tableColumns"
            :key="col.key"
          >  <slot
            :name="cellSlotName(col.key)"
            :data="item"
          >
            {{ getValueOfItem(col.key, item) }}
          </slot>
          </b-td>
        </b-tr>
      </draggable>
      <tbody v-else>
        <tr>
          <td
            :colspan="tableColumns.length + 1"
            class="text-center"
          >
            <div class="text-center text-danger my-2">
              <b-spinner
                variant="primary"
                label="Text Centered"
              />
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-if="!items.length && !isBusy">
        <tr>
          <td
            :colspan="tableColumns.length + 1"
            class="text-center"
          >
            <div class="text-center my-2">
              List Empty
            </div>
          </td>
        </tr>
      </tbody>
    </b-table-simple>
    <!--Table == == == == == == == == == == == == -->
    <slot
      v-if="isPagination"
      name="pagination"
    >
      <div
        v-if="isPaginationAble"
        class="mx-2 mb-2"
      >
        <b-row>
          <!--          <b-col-->
          <!--            cols="12"-->
          <!--            sm="6"-->
          <!--            class="d-flex align-items-center justify-content-center justify-content-sm-start"-->
          <!--          >-->
          <!--            <span class="text-muted">{{ $t('table summary', { from: dataMeta.from, to: dataMeta.to, of: dataMeta.of }) }} </span>-->
          <!--          </b-col>-->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted-black">
              {{ $t('Showing') }}
              <b-form-select
                v-model="perPage"
                :options="perPageOptions"
                class="per-page-select"
              />
              out of {{ dataMeta.of }} records
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="total > 25"
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>
    </slot>
  </div>
</template>

<script>

import {
  BTable,
  BRow,
  BCol,
  BPagination,
  BButton,
  BSpinner,
  BFormCheckbox,
  VBTooltip,
  BTableSimple,
  BTbody,
  BThead,
  BTd,
  BTr,
  BTh,
  BTfoot,
  BFormSelect,
} from 'bootstrap-vue'
import Vue from 'vue'
import tableConfig from '@/libs/tableConfig'
import ButtonDashed from '@/@core/components/b-button-dashed/ButtonDashed.vue'
import draggable from 'vuedraggable'
import { debounce } from '@core/utils/utils'
import Autosuggest from './AutoSuggest/Autosuggest.vue'

export default {
  name: 'LDraggableTable',
  components: {
    BFormSelect,
    BTable,
    BRow,
    BCol,
    BPagination,
    BButton,
    BSpinner,
    ButtonDashed,
    Autosuggest,
    BFormCheckbox,
    BTableSimple,
    BTbody,
    BThead,
    BTd,
    BTr,
    BTfoot,
    BTh,
    draggable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    tableConfigOptions: {
      type: Object,
      default: () => ({}),
    },
    tableColumns: {
      type: Array,
      required: true,
    },
    moduleName: {
      type: String,
      required: true,
    },
    tableProps: {
      type: Object,
      default: () => ({}),
    },
    createPageUrl: {
      type: [Object, String],
      default: () => '',
    },
    searchProps: {
      type: Object,
      default: () => ({}),
    },
    searchInputProps: {
      type: Object,
      default: () => ({}),
    },
    isAutoSuggest: {
      type: Boolean,
      default: false,
    },
    isPrint: {
      type: Boolean,
      default: false,
    },
    isImport: {
      type: Boolean,
      default: false,
    },
    isDelete: {
      type: Boolean,
      default: false,
    },
    isDeleteBtnDisabled: {
      type: Boolean,
      default: false,
    },
    filterClass: {
      type: [Object, null],
      default: null,
    },
    isSearchable: {
      type: Boolean,
      default: true,
    },
    isPaginationAble: {
      type: Boolean,
      default: true,
    },
    rowClicked: {
      type: [Function, String, null],
      default: null,
    },
    tableSuggestionConfigs: {
      type: Array,
      required: false,
      default: () => [],
    },
    isSuggestionTable: {
      type: Boolean,
      default: () => false,
    },
    autoSuggestStyle: {
      type: Object,
      default: () => {},
    },
    isReloadOnSelectSuggestItem: {
      type: Boolean,
      default: true,
    },
    dragOptions: {
      type: Object,
      default: () => {},
    },
    trashListPath: {
      type: String,
      default: '',
    },
    backToListPath: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      suggestions: [],
      filterToggle: false,
      batchDeleteArr: [],
      items: [],
      isDragIconVisible: false,
      dragIconVisibleId: null,
    }
  },
  computed: {
    headSlotNames() {
      return this.tableColumns.map(item => `head(${item.key})`)
    },
    cellSlotNames() {
      return this.tableColumns.map(item => `cell(${item.key})`)
    },
    tableList() {
      return this.$store.state[this.moduleName][this.moduleName]
    },
    isAllChecked() {
      if (this.tableList.length) {
        return this.tableList?.length === this.batchDeleteArr.length
      }
      return false
    },
    isBatchDeleteArrNotEmpty() {
      return this.batchDeleteArr.length === 0
    },
  },
  created() {
    this.fetchTableItemsList()
  },
  methods: {
    headSlotName(key) {
      return `head(${key})`
    },
    cellSlotName(key) {
      return `cell(${key})`
    },
    fetchTableItemsList() {
      return this.list(this, data => {
        this.items = data
      })
    },
    getHeader: col => col.label ?? col.key,
    getValueOfItem: (key, item) => item[key],
    onDragStart() {
      this.$emit('onDragStart', this.items)
    },
    onDragEnd() {
      this.$emit('onDragEnd', this.items)
    },
    onChoose(item) {
      this.$emit('onChoose', item)
    },
    onMouseEnterToTableRowAppearDragIcon(id) {
      this.dragIconVisibleId = id
    },
    onMouseLeaveToTableRowAppearDragIcon() {
      this.dragIconVisibleId = null
    },
    defaultDragOptions() {
      const defaultDragOptions = {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
      return {
        ...defaultDragOptions,
        ...this.dragOptions,
      }
    },
    myRowClickHandler(data, row, el) {
      if (this.rowClicked) {
        if (typeof this.rowClicked === 'function') {
          this.rowClicked(data)
        } else {
          this.gotoNewPage({ name: this.rowClicked, path: `${data.id}`, params: { id: data.id } }, el)
        }
      }
    },
    getSuggestProps() {
      const defaultProps = {
        'render-suggestion': this.renderSuggestion,
        'get-suggestion-value': this.getSuggestionValue,
      }
      return {
        ...defaultProps,
        ...this.searchProps,
      }
    },
    getSearchInputProps() {
      const defaultInputProps = {
        id: 'table-search',
        placeholder: 'Search',
        class: 'form-control',
        name: 'table-search',
      }
      return {
        ...defaultInputProps,
        ...this.searchInputProps,
      }
    },
    renderSuggestion(suggestion) {
      return suggestion.item.name
    },
    getSuggestionValue(suggestion) {
      const { item } = suggestion
      return item.cust_name_dba
    },
    getSelectOptions(params) {
      this.suggestions = []
      if (this.isAutoSuggest && params && params.length > 2) {
        this.$store.dispatch(this.endpoint, {
          search: params,
          per_page: 50,
        }).then(res => {
          const { data } = this.getBody(res)
          this.suggestions = [{ data }]
        })
      }
    },
    fetchResults(searchQuery) {
      this.search(searchQuery, this)
    },
    search: debounce((searchQuery, ctx) => {
      ctx.getSelectOptions(searchQuery)
    }, 500),

    selectHandler(suggest) {
      this.$emit('searchSelected', suggest, this)
      if (!this.isReloadOnSelectSuggestItem) return
      this.reloadData()
    },

    importButtonHandler() {
      this.$emit('importButtonHandler')
    },
    deleteButtonHandler() {
      this.confirmNotification(this, this.batchDeleteArr, `${this.moduleName}/batchDelete`)
        .then(() => {
          this.reloadData()
          this.$nextTick(() => {
            // eslint-disable-next-line no-unused-expressions
            this.$refs?.deleteBtn.$el.setAttribute('disabled', true)
          })
        })
    },
    getAllIds() {
      if (this.tableList.length === this.batchDeleteArr.length) {
        this.batchDeleteArr = []
      } else if (this.batchDeleteArr.length === 0) {
        this.batchDeleteArr.push(...this.tableList.map(customer => customer.id))
      } else {
        // eslint-disable-next-line array-callback-return,consistent-return
        const filteredArr = this.tableList.map(customer => customer.id).filter(id => {
          if (!this.batchDeleteArr.includes(id)) {
            return id
          }
        })
        this.batchDeleteArr.push(...filteredArr)
      }
    },
    addToBatchArr(id) {
      if (this.batchDeleteArr.includes(id)) {
        this.batchDeleteArr = this.batchDeleteArr.filter(batchId => batchId !== id)
      } else {
        this.batchDeleteArr.push(id)
      }
    },
    isChecked(id) {
      return !!this.batchDeleteArr.find(batchId => batchId === id)
    },
    onFilter(filter) {
      // eslint-disable-next-line no-restricted-syntax,guard-for-in
      for (const field in filter) {
        this.extraParams[field] = filter[field]
      }
      this.reloadData()
    },
    searchQueryClear() {
      this.searchQuery = ''
      this.reloadData()
    },
    reloadData() {
      this.filterToggle = false
      this.fetchTableItemsList()
    },
  },
  setup(props) {
    const { filterClass } = props
    if (filterClass && filterClass.filterComponent) {
      Vue.component(`${props.moduleName}FilterComponent`, () => filterClass.filterComponent)
    }
    return {
      ...tableConfig(props.moduleName, props.tableConfigOptions, props.filterClass),
    }
  },
}
</script>
<style scoped>
.filterTitle {
  margin-right: 8px;
  font-size: 16px;
}

.searchButton {
  margin: 0 0 0 16px !important;
  padding: 9px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';

 .opacity-50 {
  opacity: 0.50;
}

.line-btn {
  height: 12px;
  padding: 2px 2px;
  width: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.400ms ease;

  &--unVisible {
    opacity: 0;
    visibility: hidden;
  }

  &--visible {
    opacity: 1;
    visibility: visible;
  }

  &--line {
    background: #C9C9C9;
    height: 1px;
    width: 100%;
    position: relative;

    &::before,
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      max-height: 1px;
      position: absolute;
    }

    &::before {
      background-color: #ADADAD;
      transform: translateY(-0.8px);
      left: 0;
    }
    &::after {
      background-color: #E2E2E2;
      transform: translateY(0.8px);
      left: 0;
    }
  }
}

//.sortable-chosen {
//  background-color: rgba(151, 149, 149, 0.38) !important;
//}

.dragg-table {
  & td {
    padding: 4px 1rem !important;
  }
}

.l-table-suggestion {
  .autosuggest__results {
    top: 3px;
    width: 300%;
    max-height: none !important;
    border-radius: 0;
  }

  .table {
    margin-bottom: 0;
  }

  .autosuggest__results-item {
    padding: 7px 10px;
  }

  & thead tr th {
    padding: 7px 10px;
  }

  & tbody tr td {
    border-radius: 0 !important;
    border-right: none !important;
    border-left: none !important;
    border-bottom: none !important;
  }
}
.dashed-btn-custom-style {
  width: 33px;
  height: 33px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  & .lightIcon {
    margin: 0;
  }
}
</style>
